import React from 'react'

const Layout = ({children}) => {
  return (
    <div className='container-fluid m-0 p-0'>
        {children}
    </div>
  )
}

export default Layout