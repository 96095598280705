import React from 'react'

const Lista = ({children}) => {
  return (
    <ul>
        {children}
    </ul>
  )
}

export default Lista