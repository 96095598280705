import React from 'react'

const Card = ({ numero, titulo, children }) => {
  return (
    <div className='my-md-4'>
        <h4 className='h5 text-dark'>Contenido <span id={numero}>#</span>{numero}</h4>
        <div>
            <h5 className='h5'><span id={numero}>#</span>{titulo}</h5>
            {children}              
        </div>
    </div>
  )
}

export default Card