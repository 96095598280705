import React from 'react';
import Card from './components/Card';
import Layout from './components/Layout';
import Lista from './components/Lista';

const App = () => {

    return (
      <Layout>
        <div className="row m-0 p-0">
            <header className='col-12 d-flex'>
                <h2 className='h2 mx-3 mt-auto mb-4 text-white'>Guia de ED2</h2>
            </header>
        </div>

        <main className='row my-4 mx-0 p-0'>
          <aside className='col-12 col-md-3 d-flex flex-column px-md-4 mx-md-3'>
              <div className='mysticky'>
                <h3 className='h3'>Contenidos</h3>
                <hr />
                <Lista>
                  <li><a href="#1">Grafos</a></li>
                  <li><a href="#2">Matriz de adyacencia</a></li>
                  {/* <li><a href="#3">Lista de adyacencia</a></li> */}
                  <li><a href="#3">Recorridos en los grafos</a></li>
                  <Lista>
                    <li><a href="#3.1">Recorrido a lo ancho</a></li>
                    <li><a href="#3.2">Recorrido en profundidad</a></li>
                  </Lista>
                </Lista>
                <h3 className='h3'>Ejercicios</h3>
                <hr />
                <Lista>
                  <li><a href="#5">Ejercicio 1</a></li>
                  <li><a href="#6">Ejercicio 2</a></li>
                  <li><a href="#7">Ejercicio 3</a></li>
                  <li><a href="#8">Ejercicio 4</a></li>
                  <li><a href="#9">Ejercicio 5</a></li>
                  <li><a href="#10">Ejercicio 6</a></li>
                  <li><a href="#11">Ejercicio 7</a></li>
                </Lista>
              </div>
          </aside>
          <div className='col-12 col-md-7 mx-md-3'>

            <Card numero="1" titulo="Grafos">
              <p>Un grafo consiste en un conjunto no vacío de elementos V, llamados vértices o nodos, y un multiconjunto E de pares no ordenados de los elementos de V, llamados arcos o aristas.</p>
              <h5 className='h5'>Grafo dirigido</h5>
              <p>Un grafo orientado o dirigido D (abreviadamente dígrafo) consiste en un conjunto no vacío de elementos V, llamados vértices o nodos y un multiconjunto E de pares ordenados de V, llamados arcos o aristas.</p>
              <h5 className='h5'>Grafo Simple</h5>
              <p>Un grafo simple G = (V,E) está compuesto por un conjunto no vacío de vértices V y un conjunto de arcos E, donde se tiene un conjunto de pares de elementos distintos de V.</p>
              <h5 className='h5'>Multigrafo</h5>
              <p>Un multigrafo G = (V,E) está compuesto por un conjunto no vacío de vértices V y un conjunto de arcos E en el que se permite que haya arcos múltiples (arcos paralelos, aquellos que conectan el mismo par de vértices).</p>
              <h5 className='h5'>Pseudografo</h5>
              <p>Un pseudografo G = (V,E) está compuesto por un conjunto no vacío de vértices V y un conjunto de arcos E en el que se permite que haya arcos múltiples (arcos paralelos) y lazos (arcos cíclicos). </p>
              <h5 className='h5'>Grafo ponderado</h5>
              <p>Un grafo ponderado G es un par G = (V,A) donde V es un conjunto finito de elementos que se denominan Vértices, A es un conjunto de pares no ordenados x,y, donde x ∈V y y ∈V, denominados aristas o arcos, donde a cada elemento de A (a cada arista) se le asocia un valor real positivo.</p>
              <h5 className='h5'>Subgrafo</h5>
              <img src="images/imgsubgrafo.png" className='img' alt="" />
              <h5 className='h5'>Otros tipos de grafo</h5>
              <img src="images/imgtiposdegrafo.png" className='img' alt="" />
              <h5 className='h5'>Camino</h5>
              <img src="images/imgcamino.png" className='img' alt="" />
              <h5 className='h5'>Longitud</h5>
              <img src="images/imglongitud.png" className='img' alt="" />
              <h5 className='h5'>Camino simple</h5>
              <img src="images/imgcaminosimple.png" className='img' alt="" />
              <h5 className='h5'>Ciclo</h5>
              <p>Diremos que el camino C es un ciclo si su longitud es al menos uno y coinciden el primer y el último vértice. </p>
              <h5 className='h5'>Bucle</h5>
              <p>Diremos que el camino C es un bucle si solo existe un vértice V que se conecta con el mismo. </p>
              <h5 className='h5'>Conexo</h5>
              <img src="images/imgconexo.png" className='img' alt="" />
            </Card>
            <Card numero="2" titulo="Matriz de adyacencia">
              <img src="images/imgmatrizady.png" className='img' alt="" />
              <img src="images/imgmatrizej.png" className='img' alt="" />
            </Card>
            {/* <Card numero="3" titulo="Lista de adyacencia">
              Incompleto
            </Card> */}

            <Card numero="3" titulo="Recorridos en los grafos">
              <h6 className='h5' id='3.1'>Recorrido a lo ancho</h6>
              <img src="images/recorridoAloAncho.png" width="900" height="500" className='img-rec' alt="" />              
              <h6 className='h5' id='3.2'>Recorrido en profundidad</h6>
              <img src="images/recorridoEnProfundidad.png" width="900" height="500" className='img-rec' alt="" />
            </Card>

            <h3 className='h3'>Ejercicios</h3>
            <hr />
            <h5 className='h4' id='5'>Ejercicio 1</h5>
            <p>Como parte de un Sistema Comercial se tiene la información de algunas ciudades importantes para el 
            comercio, de estas ciudades se conoce su nombre, cantidad de habitantes, si tienen puerto o no, se 
            registra además si tienen frontera (son aledañas) entre ellas.</p>
            <img src="images/imgejercicio1.png" className='img' alt="" />
            <p>Se desean implementar las siguientes funcionalidades:</p>
            <ul>
              <li>CiudadesImportantes() que devuelve una lista con todas aquellas ciudades que tienen más de 
              500 mil habitantes y son aledañas a una ciudad con puerto</li>
              <li>MasVecinoAPortuarias() que devuelve la ciudad que tiene la mayor cantidad de ciudades 
              vecinas (tienen frontera) con puerto</li>
              <li>SePuedeLlegar(Ciudad c1, Ciudad c2) que devuelve si se puede llegar desde la ciudad c1 a la 
              ciudad c2 moviéndose a través de las ciudades vecinas</li>
              <li>CiudadesVecinasDeMayorPoblacion() que devuelve las 3 ciudades que son aledañas entre sí y 
              tienen la mayor población en conjunto</li>
              <li>SePuedenConectar() que devuelve si se puede construir un sistema de carreteras que conecten 
              a todas las ciudades</li>
              <li>CantCiudadesIntermedias(Ciudad C1, Ciudad C2) que devuelve la cantidad mínima de ciudades 
              que hay que atravesar para llegar de una ciudad C1 a una ciudad C2, en caso de no ser posible se 
              devuelve -1</li>
            </ul>
            <h5 className='h4' id='6'>Ejercicio 2</h5>
            <p>Implemente todos los incisos del ejercicio anterior para la clase SistemaComercial con el siguiente 
            diseño</p>
            <img src="images/imgejercicio2.png" className='img' alt="" />
            <h5 className='h4' id='7'>Ejercicio 3</h5>
            <p>Las redes sociales en internet, son comunidades virtuales donde sus usuarios interactúan con personas 
            de diversos lugares del mundo con quienes comparten o desean compartir intereses comunes. Una 
            forma de representar las relaciones que existen entre las personas en una red sociales mediante un 
            grafo no dirigido, donde cada persona conformaría un vértice del grafo y la relación de amistad estaría 
            dada por las aristas. El siguiente diagrama UML presenta un posible diseño de clases</p>
            <img src="images/imgejercicio3.png" className='img' alt="" />
            <p>Se desea que usted implemente el siguiente conjunto de funcionalidades sobre la red social</p>
            <ul>
              <li>amigasEnComun(p1: Persona, p2: Persona). Esta funcionalidad lista todas las personas de sexo 
              femenino que son amigas tanto de p1 como de p2</li>
              <li>potencialAmigos(p: Persona). Devuelve los potenciales amigos que puede tener una persona p
              determinada. Un potencial amigo es de aquellas personas que no son amigas de p, la que tiene 
              la mayor cantidad de amigos no comunes a p. Si existiesen varias personas que cumplen con la 
              condición se devuelven todas</li>
              <li>propagarRumor(p1: Persona, p2: Persona). Esta funcionalidad le permite a una persona p1
              conocer si le es posible transmitir una noticia a otra persona p2 sin hacerlo de forma directa 
              (entiéndase, similar a la teoría del rumor, donde una persona le comenta algo a una segunda, 
              luego esta a una tercera y así sucesivamente, hasta llegar al destinatario de la noticia a 
              transmitir)</li>
              <li>listarComunidades(). Se puede definir como una comunidad dentro de la red social, a un grupo
              de personas que se relacionan tanto directa como indirectamente (entiéndase: para que una 
              persona determinada forme parte de una comunidad, ella tiene que tener al menos un amigo 
              que forme parte de la misma). Esta funcionalidad devuelve una lista de todas las comunidades 
              que existen en la red social</li>
            </ul>
            <h5 className='h4' id='8'>Ejercicio 4</h5>
            <p>Basándonos en las definiciones hechas en el ejercicio anterior, se decide diseñar la red social de otra 
            forma. El siguiente diagrama UML muestra las clases definidas</p>
            <img src="images/imgejercicio4.png" className='img' alt="" />
            <p>En el diagrama, el atributo listaPersonas representa todas las personas que forman parte de la red.</p>
            <p>Implemente las funcionalidades siguientes</p>
            <ul>
              <li>chicaPopular(p: Persona). Devuelve si la persona p es una chica popular. Para que una chica sea 
              popular debe tener como amigos a más del 60% de los hombres en la red social y cada una de 
              las amigas que tenga conoce al menos un tercio de las personas en la red</li>
              <li>pocoPopulares(). Devuelve una lista de las personas poco populares dentro de la red. Una 
              persona se considera poco popular cuando tiene como máximo dos amigos</li>
              <li>censurarGrupo(edad: int). A veces es necesario censurarle determinado contenido a personas 
              que comparten algún o algunos criterios. En este caso se le solicita desarrolle una funcionalidad 
              que devuelva una lista de personas que contengan dos o más amigos con una edad inferior a la 
              que se pasa por parámetro y que además dichos amigos también formen parte del grupo a 
              censurar.</li>
            </ul>
            <h5 className='h4' id='9'>Ejercicio 5</h5>
            <p>Dado el siguiente grafo representado mediante lista de vértices y matriz de adyacencia.</p>
            <img src="images/imgejercicio5.png" className='img' alt="" />
            <p>Diga</p>
            <ul>
              <li>Si el grafo es dirigido o no.</li>
              <li>El grado de todos los vértices del grafo.</li>
              <li>Los caminos posibles entre los vértices A y E.</li>
              <li>Los caminos posibles entre los vértices D y H.</li>
              <li>Los ciclos simples que existen en el grafo.</li>
              <li>¿Es el grafo conexo?</li>
              <li>Realice recorridos en profundidad que comiencen por cada uno de los vértices del grafo.</li>
              <li> Realice recorridos a lo ancho que comiencen por cada uno de los vértices del grafo</li>
            </ul>
            <h5 className='h4' id='10'>Ejercicio 6</h5>
            <p>En una ciudad se desean ubicar puestos de atención a la población (PAP) en las distintas localidades. Un 
            PAP ubicado en una localidad brinda servicio a esta y a sus localidades vecinas, por lo que no es 
            permitido que existan PAP en localidades vecinas y a su vez todas las localidades deben contar con el 
            servicio. Pudiera ocurrir que una localidad sea vecina a la vez de dos o más localidades que tengan un 
            PAP, en este caso cualquiera de estos puede brindarle servicio. Implemente la funcionalidad 
            LocalidadesPAP() que devuelva una lista con las localidades donde se ubicarían los PAP.</p>
            <img src="images/imgejercicio6.png" className='img' alt="" />
            <h5 className='h4' id='11'>Ejercicio 7</h5>
            <p>Un chofer tiene que trasladar una carga desde una ciudad origen a una destino, por cada ciudad que 
            pase en su recorrido debe pagar impuesto por concepto de peaje, excepto en la ciudad origen. Se desea 
            saber cuál es la cantidad de ciudades que debe visitar para que el pago de impuestos sea mínimo.
            Implemente la funcionalidad CantCiudadesAVisitar(origen: Ciudad, destino: Ciudad): int.</p>
            <img src="images/imgejercicio7.png" className='img' alt="" />
          </div>
        </main>
        <div className="row m-0 p-0">
          <footer className='col-12  bg-dark d-flex flex-column'>
              <h2 className='text-white h4 mx-auto'>Th!sCode</h2>
              <p className='text-white mx-auto'>Sitio web desarrollado por <span className='text-warning'>Lacho06dev</span> &copy; 2022</p>
          </footer>
        </div>
      </Layout>
    );
}

export default App;

